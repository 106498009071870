import { put, takeLatest, all, call } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Update Purchase Order */
function* updateOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/purchase-manager/update-bill-of-lading/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Order Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
function* getOrderDetailsByOrderId(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/purchase-manager/get-purchase-order-detail/${payload?._id}`,
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* getVizionCantainerLocation(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/purchase-manager/get-vizion-container-location/${payload?._id}`,
        );
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Fetch SAP Orders  */
function* getOrderSAP(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });

        const res: ReturnType<any> = yield call(api, `/v1/api/integration-manager/get-sap-purchase-orders`, 'GET');
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Fetch SAP Orders  */
function* getVizionContainer(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/purchase-manager/get-vizion-container`, 'POST', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
// container Updated
function* updateContainer(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/purchase-manager/update-container-details/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Container Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Close Purchase Order Status */
function* closePurchaseOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/integration-manager/close-purchase-order/${payload?._id}`,
            'GET',
        );
        if (res.status) {
            return yield res;
            3;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Create Purchase Order Status */
function* createPurchaseOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/purchase-manager/create-purchase-order', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Purchase Order created successfully', 'success');
            return yield res;
            3;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Track Purchase Order Status */
function* trackPurchaseOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/integration-manager/purchase-order-tracking/${payload?.bill_of_lading}`,
            'GET',
        );
        if (res.status) {
            return yield res;
            3;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* getPurchaseOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/integration-manager/get-purchase-order/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* updatePurchaseOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/purchase-manager/edit-purchase-order', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Purchase Order updated successfully', 'success');
            return yield res;
            3;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

function* splitOrder(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/purchase-manager/split-purchase-order', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Order has been split successfully', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.PURCHASE_ORDER_UPDATE, withCallback(updateOrder as any));
    yield takeLatest(ActionTypes.PURCHASE_ORDER_CONTAINER_UPDATE, withCallback(updateContainer as any));
    yield takeLatest(ActionTypes.PURCHASE_ORDER_DETAIL, withCallback(getOrderDetailsByOrderId as any));
    yield takeLatest(ActionTypes.PURCHASE_ORDER_GET_SAP, withCallback(getOrderSAP as any));
    yield takeLatest(
        ActionTypes.PURCHASE_ORDER_VIZION_CANTAINER_LOCATION,
        withCallback(getVizionCantainerLocation as any),
    );

    yield takeLatest(ActionTypes.PURCHASE_ORDER_VIZION_CANTAINER, withCallback(getVizionContainer as any));
    yield takeLatest(ActionTypes.CLOSE_PURCHASE_ORDER, withCallback(closePurchaseOrder as any));
    yield takeLatest(ActionTypes.CREATE_PURCHASE_ORDER, withCallback(createPurchaseOrder as any));
    yield takeLatest(ActionTypes.TRACK_PURCHASE_ORDER, withCallback(trackPurchaseOrder as any));
    yield takeLatest(ActionTypes.GET_PURCHASE_ORDER, withCallback(getPurchaseOrder as any));
    yield takeLatest(ActionTypes.UPDATE_PURCHASE_ORDER, withCallback(updatePurchaseOrder as any));
    yield takeLatest(ActionTypes.SPLIT_PURCHASE_ORDER, withCallback(splitOrder as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
