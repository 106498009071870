import { put, takeLatest, all, call, debounce, select } from 'redux-saga/effects';
import { withCallback } from 'redux-saga-callback';

import { api } from '@/utils/axiosInterceptor';
import { ACTION } from '@/store/interfaces';
import ActionTypes from '@/store/types';
import { handleErrors, toastr } from '@/utils/helpers';

/* Get Addresses List */
function* getAddresses(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/address-manager/address-list', 'POST', { data: payload });
        if (res.status) {
            yield put({ type: 'addresses', payload: { model: 'addresses', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Addresses List By Tag */
function* getAddressesByTag(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/address-manager/address-list?tag=${payload?.tag}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            yield put({ type: 'addresses', payload: { model: 'addresses', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Addresses List By Tag */
function* getWarehousesByTag(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/warehouse-manager/warehouse-list?tag=${payload?.tag}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            yield put({ type: 'addresses', payload: { model: 'addresses', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
function* getCustomerSAP(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/integration-manager/get-sap-customer?name=${payload?.tag}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            yield put({ type: 'addresses', payload: { model: 'addresses', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Get Address Detail User */
function* getAddressDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/address-manager/address-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Create Fleet  User Address */
function* addAddress(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/address-manager/add-address', 'POST', { data: payload });
        if (res.status) {
            toastr('Address Successfully Added', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Create Fleet  User Lane */
function* addLane(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/lane-manager/add-lane', 'POST', { data: payload });
        if (res.status) {
            toastr('Lane Successfully Added', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Fleet  User Address */
function* updateAddress(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/address-manager/update-address/${payload?._id}`, 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Address Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Fleet  User Address */
function* removeAddress(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/address-manager/remove-address/${payload?._id}`,
            'DELETE',
        );
        if (res.status) {
            const body = {
                limit: state?.default?.addresses?.limit,
                page: state?.default?.addresses?.page,
            };
            yield put({
                type: ActionTypes.GET_DEFAULT_DATA_MODEL,
                payload: { pathname: 'address-manager/address-list', model: 'addresses', ...body },
            });
            toastr('Address Successfully Removed', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}
/* Remove Fleet  User Lane Address */
function* removeLaneAddress(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/remove-lane/${payload?._id}`, 'DELETE');
        if (res.status) {
            const body = {
                limit: state?.default?.lanes?.limit,
                page: state?.default?.lanes?.page,
            };
            yield put({
                type: ActionTypes.GET_DEFAULT_DATA_MODEL,
                payload: { pathname: 'lane-manager/lane-list', model: 'lanes', ...body },
            });
            toastr('Lane Successfully Removed', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Lane Address */
function* getAllLaneAddresses(): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/lanes`);
        if (res.status) {
            yield put({ type: 'searchlaneslist', payload: { model: 'searchlaneslist', data: res.data } });
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Address Detail User */
function* getLaneDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/detail-lane/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Address Detail User */
function* updateLane(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/update-lane/${payload?._id}`, 'PUT', {
            data: payload,
        });
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Users by Lane */
function* getUsersByLaneId(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/get-lane-users/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Past Loads by Lane */
function* getPastLoadsbyLane(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/get-pastloads-lane/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Curernt Loads by Lane */
function* getCurrentLoadsbyLane(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/lane-manager/get-currentloads-lane/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Create Warehouse */
function* addWarehouse(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, '/v1/api/warehouse-manager/add-warehouse', 'POST', {
            data: payload,
        });
        if (res.status) {
            toastr('Address Successfully Added', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Update Warehouse */
function* updateWarehouse(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/warehouse-manager/update-warehouse/${payload?._id}`,
            'POST',
            {
                data: payload,
            },
        );
        if (res.status) {
            toastr('Address Successfully Updated', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Remove Warehouse */
function* removeWarehouse(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const state: any = yield select();
        const res: ReturnType<any> = yield call(
            api,
            `/v1/api/warehouse-manager/delete-warehouse/${payload?._id}`,
            'DELETE',
        );
        if (res.status) {
            const body = {
                limit: state?.default?.lanes?.limit,
                page: state?.default?.lanes?.page,
            };
            yield put({ type: 'addresses', payload: { model: 'addresses', data: res.data } });
            toastr('Warehouse Successfully Removed', 'success');
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* Get Warehouse Detail */
function* getWarehouseDetail(action: ACTION): Generator<unknown> {
    try {
        yield put({ type: ActionTypes.LOADING });
        const { payload } = action;
        const res: ReturnType<any> = yield call(api, `/v1/api/warehouse-manager/warehouse-detail/${payload?._id}`);
        if (res.status) {
            return yield res;
        } else {
            // Handle Errors
            if (res.errors) return yield handleErrors(res.errors);
        }
    } catch (error) {
        yield put({ type: ActionTypes.LOADING_STOP });
    }
}

/* All Generator Will be available in Default Watchers */
function* defaultWatchers() {
    yield takeLatest(ActionTypes.ADDRESS_FETCH, withCallback(getAddresses as any));
    yield debounce(1000, ActionTypes.ADDRESS_FETCH_BY_TAG, withCallback(getAddressesByTag as any));
    yield debounce(1000, ActionTypes.WAREHOUSE_BY_TAG, withCallback(getWarehousesByTag as any));
    yield debounce(1000, ActionTypes.CUSTOMER_SAP_BY_TAG, withCallback(getCustomerSAP as any));
    yield takeLatest(ActionTypes.ADDRESS_DETAIL, withCallback(getAddressDetail as any));
    yield takeLatest(ActionTypes.ADDRESS_ADD, withCallback(addAddress as any));
    yield takeLatest(ActionTypes.ADDRESS_ADD_LANE, withCallback(addLane as any));
    yield takeLatest(ActionTypes.ADDRESS_UPDATE_LANE, withCallback(updateLane as any));
    yield takeLatest(ActionTypes.ADDRESS_UPDATE, withCallback(updateAddress as any));
    yield takeLatest(ActionTypes.ADDRESS_REMOVE, withCallback(removeAddress as any));
    yield takeLatest(ActionTypes.ADDRESS_REMOVE_LANE, withCallback(removeLaneAddress as any));
    yield takeLatest(ActionTypes.ADDRESS_FETCH_LANES, withCallback(getAllLaneAddresses as any));
    yield takeLatest(ActionTypes.ADDRESS_FETCH_DETAIL_LANE, withCallback(getLaneDetail as any));
    // Lane History
    yield takeLatest(ActionTypes.ADDRESS_FETCH_USERS_BY_LANE, withCallback(getUsersByLaneId as any));
    yield takeLatest(ActionTypes.ADDRESS_FETCH_PAST_LOADS_BY_LANE, withCallback(getPastLoadsbyLane as any));
    yield takeLatest(ActionTypes.ADDRESS_FETCH_CURRENT_LOADS_BY_LANE, withCallback(getCurrentLoadsbyLane as any));
    // Warehouse
    yield takeLatest(ActionTypes.WAREHOUSE_ADD, withCallback(addWarehouse as any));
    yield takeLatest(ActionTypes.WAREHOUSE_UPDATE, withCallback(updateWarehouse as any));
    yield takeLatest(ActionTypes.WAREHOUSE_REMOVE, withCallback(removeWarehouse as any));
    yield takeLatest(ActionTypes.WAREHOUSE_DETAIL, withCallback(getWarehouseDetail as any));
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([defaultWatchers()]);
}
